<template>
    <div>
        <b-button @click="$emit('click')"
                  type="button"
                  :variant="variant"
                  :class="cssClass" :disabled="processing || disabled"
        >
            <b-spinner label="Spinning" class="align-middle mr-2" small
                    v-show="processing">
            </b-spinner>
            {{ $t(label) | toUpperCase }}
        </b-button>
    </div>
</template>
<script>
    export default {
        props: {
            label:{
                default: "save",
            },
            cssClass:{
                default: ""
            },
            variant:{
                default: "primary"
            },
            processing:{
                default: false
            },
            disabled:{
                default: false
            }
        },
        methods: {
            onHide(event) {
                if(!this.onHideOnlyX) {
                    this.$emit('bHideModal',this.id)
                }

                if (event.trigger === "backdrop" && this.onHideOnlyX == true) {
                    event.preventDefault();
                    this.$emit('bHideModalBackDrop',this.id);
                }
                if (event.trigger === "headerclose") {
                    this.$emit('bHideModalHeaderClose',this.id);
                }
            }
        }
    }
</script>
